import { ICigarLog } from '@models';
import { BaseModel } from './base.model';
import { ProductModel } from './product.model';
import _cloneDeep from 'lodash-es/cloneDeep';

export const JOURNAL_LIST = 'journal';
export const FAVORITES_LIST = 'favorites';
export const WISH_LIST = 'wishlist';

export enum CigarListEnum {
  JOURNAL_LIST = 'journal',
  FAVORITES_LIST = 'favorites',
  WISH_LIST = 'wishlist',
}

/**
 * @deprecated use ICigarLog interface and ngrx
 */
export class CigarLogModel extends BaseModel {
  protected _baseData;
  static nestedModels = {
    Product: ProductModel,
  };

  static mappings = {
    CigarDetails: 'Product',
  };

  constructor(data: Object) {
    // if (data instanceof CigarLogModel) return data;
    // debugger;
    // console.log('CigarLogModel', { data }, data instanceof CigarLogModel);
    const clone = _cloneDeep(data);
    super(data);
    if (data instanceof CigarLogModel) {
      this._baseData = data.getNewInterface();
    } else {
      this._baseData = clone;
    }
  }

  getNewInterface(): ICigarLog {
    return this._baseData;
  }

  Id: number;
  _Id: string;
  Date: string;
  Name: string;
  LineId: number;
  List: 'favorites' | 'wishlist' | 'journal';
  ProductId: number;
  Quantity: number;
  UUID: string;
  UserId: string;
  Product: ProductModel;
  UserImageUrl: string;
  UserImageDate: string;
  Location: string;
  Price: number;
  ModifiedOn: string;
  RecognitionId?: number;
  LiteModel: boolean;
}
